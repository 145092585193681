import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from 'components/Layout'
import { Title, Text, Grid, Button } from 'components/theme'
import Map from 'components/Map'
import SEO from 'components/SEO'
import { Box, Card, Container, Divider, Flex } from 'styles/globalStyle'
import { CentersPageTypes } from 'types/centersTypes'

type CentersPageParams = {
  data: CentersPageTypes
}

const CentersPage: FC<CentersPageParams> = ({ data }) => {
  const { page, centers, upcomingCenters } = data

  return (
    <Layout onlyFooter>
      <SEO
        title={page.data.title_meta.text}
        description={page.data.description_meta.text}
      />
      <Container textAlign="center" py="4rem">
        <Text color="primary.700" fontWeight="600" mb="0.75rem">
          {page.data.subheading.text}
        </Text>
        <Title>{page.data.title.text}</Title>
      </Container>
      <Map height="513px" />
      <Container py="4rem">
        <Title as="h2" size="sm" fontWeight="600" textAlign="center">
          Tous nos centres
        </Title>
        <Divider mt="0.75rem" mb="2.5rem" />
        <Grid
          columns={['1fr', '1fr 1fr', '', '', '', 'repeat(3, 1fr)']}
          spacing="2rem"
          alignItems="flex-start"
        >
          {centers.nodes.map((center, idx) => (
            <Card
              key={idx}
              border="1px solid"
              borderColor="gray.200"
              padding="1rem"
            >
              <Text size="lg" color="gray.900" fontWeight="600" mb="0.5rem">
                {center.data.title.text}
              </Text>
              <GatsbyImage
                alt={center.data.image.alt}
                image={center.data.image.gatsbyImageData}
                style={{
                  height: '180px',
                }}
                imgStyle={{
                  borderRadius: '0.5rem',
                }}
              />
              <Flex flexDirection="column" flex="1" mt="1rem">
                <Box flex="1">
                  <Text mb="0.5rem">{center.data.address.text}</Text>
                  {center.data.phone_number.text && (
                    <Text>
                      Tél :{' '}
                      <a href={`tel:${center.data.phone_number.text}`}>
                        {center.data.phone_number.text}
                      </a>
                    </Text>
                  )}
                  <Text>
                    Email :{' '}
                    <a href={`mailto:${center.data.email.text}`}>
                      {center.data.email.text}
                    </a>
                  </Text>
                </Box>
                <Button
                  to={center.url}
                  type="gradient"
                  mt="1.5rem"
                  width="calc(100% - 2rem)"
                >
                  Plus d'informations
                </Button>
              </Flex>
            </Card>
          ))}
        </Grid>
      </Container>
      <Container pb="4rem">
        <Title as="h2" size="sm" fontWeight="600" textAlign="center">
          Ouvertures prochaines
        </Title>
        <Divider mt="0.75rem" mb="2.5rem" />
        <Grid
          columns={['1fr', '1fr 1fr', '', '', '', 'repeat(3, 1fr)']}
          spacing="2.5rem"
        >
          {upcomingCenters.nodes.map((center, idx) => (
            <Card
              key={idx}
              border="1px solid"
              borderColor="gray.200"
              display="flex"
              flexDirection="column"
              padding="1rem"
            >
              <Text size="lg" color="gray.900" fontWeight="600" mb="0.5rem">
                {center.data.title.text}
              </Text>
              <Box position="relative">
                <GatsbyImage
                  alt={center.data.image.alt}
                  image={center.data.image.gatsbyImageData}
                  style={{
                    height: '180px',
                  }}
                  imgStyle={{
                    borderRadius: '1rem',
                  }}
                />
                <Box
                  bg="error.600"
                  padding="0.25rem 0.5rem"
                  position="absolute"
                  top="0.5rem"
                  left="0.5rem"
                  textAlign="center"
                  borderRadius="1rem"
                >
                  <Text color="white" fontWeight="500" size="sm">
                    Ouverture prochaine
                  </Text>
                </Box>
              </Box>
              <Flex flexDirection="column" flex="1">
                <Button
                  to="/appointment"
                  type="gradient"
                  mt="1.5rem"
                  width="calc(100% - 2rem)"
                >
                  Prendre rendez-vous
                </Button>
              </Flex>
            </Card>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    page: prismicListCentersPage {
      data {
        title_meta {
          text
        }
        description_meta {
          text
        }
        title {
          text
        }
        subheading {
          text
        }
      }
    }
    centers: allPrismicCenters(
      sort: { fields: first_publication_date }
      filter: { data: { is_open: { eq: true } } }
    ) {
      nodes {
        data {
          title {
            text
          }
          phone_number {
            text
          }
          email {
            text
          }
          address {
            text
          }
          image {
            alt
            gatsbyImageData
          }
        }
        url
      }
    }
    upcomingCenters: allPrismicCenters(
      sort: { fields: type }
      filter: { data: { is_open: { eq: false } } }
    ) {
      nodes {
        data {
          is_open
          title {
            text
          }
          image {
            alt
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default CentersPage
